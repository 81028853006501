<template>
  <div class="section-features">
    <div class="container">
      <div class="great-title">
        <h2>
          {{ this.$store.state.langs.UNIQUE_SERVICE }}
        </h2>
      </div>
      <div class="subtitle">
        <span>
          {{ this.$store.state.langs.YOU_JUST_PLACE_AN_ORDER }}
        </span>
      </div>
      <div class="features">

        <div v-if="features" class="feature"
             v-for="(item, index) in features"
             :key="index"
        >
          <div class="feature-icon">
            <img :src="item.PROPERTY_FILE_VALUE" :alt="item.NAME+index">
          </div>
          <div class="xs-title">
            {{ item.NAME }}
          </div>
          <div class="caption" v-html="item.PREVIEW_TEXT"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: {
            scrollcontent: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                features: {},
                requests: true
            }
        },
        created(){
            if( this.scrollcontent ) {
                window.addEventListener('scroll', () => {
                    if (pageYOffset > 200) {
                        this.featuresRequest();
                    }
                });
            }else{
                this.featuresRequest();
            }
        },
        mounted() {},
        methods: {
            async featuresRequest() {
                if(this.requests) {
                    this.requests = false;
                    const responseFeatures = (
                        await this.$axios({
                            url: '/ajax/features.php',
                            method: 'post',
                            params: {
                                clear_cache: this.getGet('clear_cache'),
                                SITE_ID: this.$store.state.langs.SITE_ID
                            }
                        })
                    )
                    if (responseFeatures) {
                        this.features = responseFeatures.data
                    } else {
                        console.log('responseFeatures')
                    }
                }
            },
            getGet(name) {
                var searchString = new URLSearchParams(window.location.search);
                var res = searchString.get(name);
                return res;
            }
        },
    }
</script>

<style lang="scss" scoped>
.section-features {
  padding-bottom: 7em;
}
.features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5em;
  margin-top: 4em;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -2em;
    left: 0;
    width: 100%;
    height: 1px;
    background: #fff;
  }
  .feature-icon {
    background: var(--bg);
    width: 3.75em;
    height: 3.75em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    & + * {
      margin-bottom: 0.5em;
    }
  }
  .caption {
    opacity: 0.5;
  }
  .feature {
    position: relative;
    &:after {
      content: '';
      width: 1px;
      height: 100%;
      right: -1em;
      top: 0;
      position: absolute;
      background: currentColor;
      opacity: 0.1;
    }
    &:before {
      content: '';
      width: 85%;
      height: 1px;
      bottom: -2em;
      left: 0;
      position: absolute;
      background: currentColor;
      opacity: 0.1;
    }
    &:nth-child(4n + 4) {
      &:after {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
