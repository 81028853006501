import Vue from '@/index.js'

import page from '@/mixins/page'

import sectionBanner from '@/components/sections/section-banner'
import sectionCategories from '@/components/sections/section-categories'
import sectionProducts from '@/components/sections/section-products'
import sectionFeatures from '@/components/sections/section-features'
import sectionTestimonials from '@/components/sections/section-testimonials'
import sectionClients from '@/components/sections/section-clients'
import sectionLastPosts from '@/components/sections/section-last-posts'
import layoutCategoryCard from '@/components/layout/category/layout-category-card'
import layoutTestimonialCard from '@/components/layout/layout-testimonial-card'
import layoutPostCard from '@/components/layout/layout-post-card'

export default new Vue({
  el: '#app',
  components: {
    sectionBanner,
    sectionCategories,
    layoutCategoryCard,
    sectionProducts,
    sectionFeatures,
    sectionTestimonials,
    layoutTestimonialCard,
    sectionClients,
    sectionLastPosts,
    layoutPostCard
  },
  mixins: [page]
})
