<template>
  <div v-if="products.length > 0" class="section-products">
    <div class="container">
      <div class="great-title">
        {{ this.$store.state.langs.TOP_SELLERS }}
      </div>
      <div class="subtitle">
        {{ this.$store.state.langs.MOST_POPULAR_PRODUCTS }}
      </div>
      <!--  -->
      <div v-if="products" class="products">
        <layout-product-card
                v-for="(item, index) in products"
                :key="index"
                :product='item'>
        </layout-product-card>

      </div>
      <div class="button-wrapper">
        <a title="" :href="this.$store.state.langs.SITE_DIR+'/catalog/'">
          <ui-button>{{  this.$store.state.langs.VIEW_ALL_BESTSELLERS }}</ui-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
    import layoutProductCard from '@/components/layout/product/layout-product-card'
    export default {
        components: {
            layoutProductCard
        },
        props: {
            scrollcontent: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                products: {},
                requests: true
            }
        },
        created(){
            if( this.scrollcontent ) {
                this.requests = false;
                window.addEventListener('scroll', () => {
                    if (pageYOffset > 200) {
                        this.productsRequest();
                    }
                });
            }else{
                this.productsRequest();
            }
        },
        mounted() {},
        methods: {
            async productsRequest() {
                if(this.requests) {
                    const responseProducts = (
                        await this.$axios({
                            url: '/ajax/section-products.php',
                            method: 'post',
                            params: {
                                clear_cache: this.getGet('clear_cache'),
                                SITE_ID: this.$store.state.langs.SITE_ID
                            }
                        })
                    )
                    if (responseProducts) {
                        this.products = responseProducts.data
                    } else {
                        console.log('responseProducts')
                    }
                }
            },
            getGet(name) {
                var searchString = new URLSearchParams(window.location.search);
                var res = searchString.get(name);
                return res;
            }
        },
        computed: {
            moredetail() {
                return this.$store.state.langs.MORE_DETAILS
            }
        }
    }
</script>

<style lang="scss" scoped>
.section-products {
  padding-bottom: 5em;
}
.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5em 3em;
  margin-top: 4em;
}
.button-wrapper {
  text-align: center;
  margin-top: 5em;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px 15px;
  }
}
</style>
