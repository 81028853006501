<template>
  <div class="section-last-posts">
    <div class="container">
      <div class="wrapper">
        <div class="middle-title">
          <h2>
            {{ this.$store.state.langs.TITLE_BLOG }}
          </h2>
        </div>
        <div class="subtitle">
          <span>
            {{ this.$store.state.langs.TITLE_BLOG_SUB_TITLE }}
          </span>
        </div>
        <div v-if="posts" class="items">

          <a title=""
                  v-for="(item, index) in posts"
                  :key="index"
                  :href="item.DETAIL_PAGE_URL">
            <layout-post-card>
              <img slot="pic" :src="item.PREVIEW_PICTURE" :alt="item.NAME">
              <span slot="title">
                {{ item.NAME }}
              </span>
              <span slot="desc" v-html="item.PREVIEW_TEXT"></span>
            </layout-post-card>
          </a>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import layoutPostCard from '@/components/layout/layout-post-card'
    export default {
        components: {
            layoutPostCard
        },
        props: {
            scrollcontent: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                posts: {},
                requests: true
            }
        },
        created () {
            if( this.scrollcontent ) {
                window.addEventListener('scroll', () => {
                    if (pageYOffset > 200) {
                        this.postsRequest();
                    }
                });
            }else{
                this.postsRequest();
            }
        },
        methods: {
            async postsRequest() {
                if(this.requests) {
                    this.requests = false;
                    const responsePosts = (
                        await this.$axios({
                            url: '/ajax/posts-main.php',
                            method: 'post',
                            params: {
                                clear_cache: this.getGet('clear_cache'),
                                SITE_ID: this.$store.state.langs.SITE_ID
                            }
                        })
                    )
                    if (responsePosts) {
                        this.posts = responsePosts.data
                    } else {
                        console.log('responsePosts')
                    }
                }
            },
            getGet(name) {
                var searchString = new URLSearchParams(window.location.search);
                var res = searchString.get(name);
                return res;
            }
        },
    }
</script>

<style lang="scss" scoped>
.section-last-posts {
  padding-bottom: 5em;
}
.wrapper {
  background: var(--bg);
  padding: 4em;
}
.items {
  margin-top: 3.5em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875em;
}
@media screen and (max-width: 767px) {
  .wrapper {
    padding: 2em;
  }
  .items {
    display: block;
    & > * {
      display: block;
      margin-bottom: 3em;
    }
  }
}
</style>
