<template>
  <div class="section-banner">
    <div class="container">
      <layout-own-carousel>
        <a title="" v-for="(item, index) in slider"
           :key="index"
           :href="item.PROPERTY_PATH_VALUE">
          <img v-lazy="item.PREVIEW_PICTURE" :alt="item.NAME+' '+index" :width="1476" :height="720">
        </a>
      </layout-own-carousel>
    </div>
  </div>
</template>

<script>
import layoutOwnCarousel from '@/components/layout/layout-own-carousel'
export default {
  components: {
    layoutOwnCarousel
  },
    data() {
        return {
            slider: {},
            width: 0
        }
    },
    created(){
        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
        this.sliderRequest();
    },
    mounted() {
        this.updateWidth();
    },
    methods: {
        async sliderRequest() {
            const responseSlider  = (
                await this.$axios({
                    url: '/ajax/main-slider.php',
                    method: 'post',
                    params: {
                        width: this.width,
                        clear_cache: this.getGet('clear_cache'),
                        SITE_ID: this.$store.state.langs.SITE_ID
                    }
                })
            )
            if (responseSlider) {
                this.slider = responseSlider.data
            } else {
                console.log('responseSlider')
            }
        },
        getGet(name) {
            var searchString = new URLSearchParams(window.location.search);
            var res = searchString.get(name);
            return res;
        },
        updateWidth() {
            this.width = window.innerWidth;
        },
    },
}
</script>

<style lang="scss" scoped>
.section-banner {
  --size: 100%;
  padding-bottom: 5em;
  min-height: MIN(680px, 60vw);
}
</style>
