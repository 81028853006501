<template>
  <div class="section-clients">
    <div v-if="brands" class="container">
      <div class="middle-title">
        <h1>
          {{ this.$store.state.langs.OUR_BRANDS }}
        </h1>
      </div>
      <div class="caption">
        <span>
          {{ this.$store.state.langs.OUR_BRANDS_SUB_TITLE }}
        </span>
      </div>
      <div class="items">
        <layout-own-carousel :xs-count="3" :count="7" gap="5em">
          <img
                  v-for="(item, index) in brands"
                  :key="index"
                  :src="item.PREVIEW_PICTURE" :alt="item.NAME"
          >
        </layout-own-carousel>
      </div>
      <div class="link">
        <a title="" :href=" this.$store.state.langs.SITE_DIR + this.$store.state.langs.LINK_CATALOG ">
          <ui-button>
            {{ this.$store.state.langs.GO_TO_CATALOG }}
          </ui-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import layoutOwnCarousel from '@/components/layout/layout-own-carousel'
export default {
  components: {
    layoutOwnCarousel
  },
    props: {
        scrollcontent: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            brands: {},
            requests: true
        }
    },
    created () {
        if( this.scrollcontent ) {
            window.addEventListener('scroll', () => {
                if (pageYOffset > 200) {
                    this.brandsRequest();
                }
            });
        }else{
            this.brandsRequest();
        }
    },
    methods: {
        async brandsRequest() {
            if(this.requests) {
                this.requests = false;
                const responseBrands = (
                    await this.$axios({
                        url: '/ajax/brands-main.php',
                        method: 'post',
                        params: {
                            clear_cache: this.getGet('clear_cache'),
                            SITE_ID: this.$store.state.langs.SITE_ID
                        }
                    })
                )
                if (responseBrands) {
                    this.brands = responseBrands.data
                } else {
                    console.log('responseBrands')
                }
            }
        },
        getGet(name) {
            var searchString = new URLSearchParams(window.location.search);
            var res = searchString.get(name);
            return res;
        }
    },
}
</script>

<style lang="scss" scoped>
.section-clients {
  text-align: center;
  padding-bottom: 5em;
}
.caption {
  width: 33em;
  max-width: 100%;
  position: relative;
  margin: 1em auto;
}
.items {
  margin-top: 3em;
}
.link {
  margin-top: 3em;
}
</style>
